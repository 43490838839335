
import { Component, Vue } from 'vue-property-decorator'
import { phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { UserUpdate } from '@/types/userAdmin'

@Component({
  name: 'UserAdd'
})
export default class extends Vue {
  private roleList: Array<{ role: string; roleName: string }> = []
  private info: UserUpdate = {
    name: '',
    mobile: '',
    loginId: '',
    role: ''
  }

  private rules = {
    name: [
      { required: true, message: '请输入姓名', trigger: ['blur', 'change'] }
    ],
    mobile: [
      { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ],
    role: [
      { required: true, message: '请输入选择角色', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  private title = '新增账号'
  created () {
    this.getRoleList()
    if (this.$route.params.id) {
      this.title = '编辑账号'
      this.userData()
    }
  }

  // 获取角色列表
  getRoleList () {
    this.$axios.get(this.$apis.role.selectIparkAdminRoleByList, {}).then((res) => {
      this.roleList = res || []
    })
  }

  // 用户详情
  userData () {
    this.$axios.get<UserUpdate>(this.$apis.userAdmin.userDetails, {
      userId: this.$route.params.id
    })
      .then((res) => {
        this.info = res
      })
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$route.params.id
          ? this.$apis.userAdmin.userUpdate
          : this.$apis.userAdmin.userAdd
        , this.info)
          .then(() => {
            this.submitShow = false
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push({ name: 'userList' })
          })
          .catch(err => {
            this.submitShow = false
            this.$message.error(err)
          })
      }
    })
  }

  mobileChange (value: string) {
    if (!this.$route.params.id) {
      this.info.loginId = value
    }
  }
}
